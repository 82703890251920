import { isUnit } from '@reshima/shared';
import {
  ItemActivityType,
  ListActivityPostRequestPayload,
  ListActivityQuery,
  ListActivityQueryFilters,
  ListActivityQueryRequest,
  NewListItemActivityV2,
} from './models';

export function isItemActivityType(type: unknown): type is ItemActivityType {
  if (typeof type !== 'string') {
    return false;
  }

  return type in ItemActivityType;
}

export function isNewListItemActivityV2(
  listItemActivity: unknown,
): listItemActivity is NewListItemActivityV2 {
  if (!listItemActivity) {
    return false;
  }

  const { type, itemId, itemName, itemCategoryId, itemUnit, itemCount } =
    listItemActivity as NewListItemActivityV2;

  if (!isItemActivityType(type)) {
    return false;
  }

  if (typeof itemId !== 'string') {
    return false;
  }

  if (typeof itemName !== 'string') {
    return false;
  }

  if (typeof itemCategoryId !== 'string') {
    return false;
  }

  if (!isUnit(itemUnit)) {
    return false;
  }

  if (typeof itemCount !== 'number') {
    return false;
  }

  return true;
}

export function isListActivityQueryFilters(
  filters: unknown,
): filters is ListActivityQueryFilters {
  if (!filters || typeof filters !== 'object') {
    return false;
  }

  const {
    listId,
    startDate,
    endDate,
    itemName,
    itemCategoryId,
    itemId,
    types,
    participantsIds,
  } = filters as ListActivityQueryFilters;

  if (typeof listId !== 'string') {
    return false;
  }

  if (startDate && typeof startDate !== 'number') {
    return false;
  }

  if (endDate && typeof endDate !== 'number') {
    return false;
  }

  if (itemName && typeof itemName !== 'string') {
    return false;
  }

  if (itemCategoryId && typeof itemCategoryId !== 'string') {
    return false;
  }

  if (itemId && typeof itemId !== 'string') {
    return false;
  }

  if (types && (!Array.isArray(types) || !types.every(isItemActivityType))) {
    return false;
  }

  if (
    participantsIds &&
    (!Array.isArray(participantsIds) ||
      !participantsIds.every((id) => typeof id === 'string'))
  ) {
    return false;
  }

  return true;
}

export function isListActivityQuery(
  query: unknown,
): query is ListActivityQuery {
  if (!query || typeof query !== 'object') {
    return false;
  }

  const { continuationToken } = query as ListActivityQuery;

  if (continuationToken && typeof continuationToken !== 'string') {
    return false;
  }

  return isListActivityQueryFilters(query);
}

export function isListActivityQueryRequest(
  query: unknown,
): query is ListActivityQueryRequest {
  if (!query || typeof query !== 'object') {
    return false;
  }

  const { startDate, endDate } = query as ListActivityQueryRequest;

  if (!startDate || typeof startDate !== 'string' || Number.isNaN(startDate)) {
    return false;
  }

  if (!endDate || typeof endDate !== 'string' || Number.isNaN(endDate)) {
    return false;
  }

  return true;
}

export function isListActivityPostRequestPayload(
  request: unknown,
): request is ListActivityPostRequestPayload {
  const items = (request as ListActivityPostRequestPayload)?.activities;

  if (!Array.isArray(items) || !items.length) {
    return false;
  }

  if (!items.every(isNewListItemActivityV2)) {
    return false;
  }

  return true;
}
