'use client';
import { FetchResponseError } from '@reshima/shared';
import { AppUser } from '@reshima/firebase';
import { postListActivityItems } from '@reshima/list-activity-shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import {
  deleteActivitiesFromLocalStorage,
  getPreviousActivitiesFromLocalStorage,
  LocalStorageActivities,
  setActivitiesInLocalStorage,
} from './list-activities-local-storage';

let isPosting = false;

function getActivitiesTelemetryProperties(
  activities: LocalStorageActivities[],
) {
  const listIdsLength = new Set<string>(activities.map(({ listId }) => listId))
    .size;
  const sessionsLength = new Set<string>(
    activities.map(({ session }) => session),
  ).size;
  const itemsLength = activities.reduce(
    (acc, { activities }) => acc + activities.length,
    0,
  );

  return {
    count: activities.length,
    listIdsLength,
    sessionsLength,
    itemsLength,
  };
}

async function postActivities({
  localStorageActivities: { listId, session, activities },
  user,
}: {
  localStorageActivities: LocalStorageActivities;
  user: AppUser;
}) {
  const name = 'PostActivities';
  const action = Action.Post;

  const limit = 50;

  const properties = {
    listId,
    session,
    limit,
    activitiesLength: activities.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    for (let i = 0; i < activities.length; i += limit) {
      const from = i;
      const to = Math.min(i + limit, activities.length);

      const pagedActivities = activities.slice(from, to);
      const restActivities = activities.slice(to);

      await postListActivityItems({
        listId,
        activities: pagedActivities,
        user,
      });

      setActivitiesInLocalStorage({
        listId,
        session,
        activities: restActivities,
      });
    }

    deleteActivitiesFromLocalStorage({ listId, session });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    const isForbidden =
      error instanceof FetchResponseError && error.response?.status === 403;

    trackException({
      name,
      action,
      properties: {
        ...properties,
        isForbidden,
      },
      start,
      error,
    });

    if (isForbidden) {
      deleteActivitiesFromLocalStorage({ listId, session });
    }
  }
}

export async function postTasksActivities({ user }: { user: AppUser }) {
  const name = 'PostTasksActivities';
  const action = Action.Post;

  if (isPosting) {
    return;
  }

  const activities = getPreviousActivitiesFromLocalStorage();

  const properties = getActivitiesTelemetryProperties(activities);

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  if (activities.length === 0) {
    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Skip,
      properties,
      start,
    });

    return;
  }

  try {
    isPosting = true;

    for (const localStorageActivities of activities) {
      await postActivities({ localStorageActivities, user });
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      properties,
      start,
      error,
    });
  } finally {
    isPosting = false;
  }
}
