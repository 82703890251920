import classNames from 'classnames';
import { ReactElement, useCallback } from 'react';
import { LiaUserSolid } from 'react-icons/lia';
import { PiCheckSquare } from 'react-icons/pi';
import { PiSquare } from 'react-icons/pi';
import { AiOutlineDelete } from 'react-icons/ai';
import { HiOutlinePlus } from 'react-icons/hi';
import {
  AppUser,
  List,
  Participant,
  updateContactName,
  UserData,
} from '@reshima/firebase';
import { formatDate, formatTime } from '@reshima/shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { CustomCategory } from '@reshima/category';
import { CategoryIcon } from '@reshima/categories-ui';
import { DebouncedInput } from '@reshima/pure-ui';
import {
  ItemActivityType,
  ListItemActivity,
} from '@reshima/list-activity-shared';

const activityTypesIcons: Record<ItemActivityType, ReactElement> = {
  [ItemActivityType.added]: <HiOutlinePlus />,
  [ItemActivityType.checked]: <PiCheckSquare />,
  [ItemActivityType.unchecked]: <PiSquare />,
  [ItemActivityType.deleted]: <AiOutlineDelete />,
};

function ItemName({
  itemCategoryId,
  itemName,
  className,
  customCategories,
}: Pick<ListItemActivity, 'itemCategoryId' | 'itemName'> & {
  className?: string;
  customCategories: Record<string, CustomCategory>;
}): ReactElement {
  const { itemNameAriaLabel } = useTranslations()['list-activity'];

  return (
    <div className={classNames('flex items-center gap-1', className)}>
      <CategoryIcon
        categoryId={itemCategoryId}
        customCategories={customCategories}
      />
      <div className="text-sm" aria-label={itemNameAriaLabel}>
        {itemName}
      </div>
    </div>
  );
}

function ActivityItemDate({
  createdAt,
  className,
}: Pick<ListItemActivity, 'createdAt'> & {
  className?: string;
}) {
  const date = new Date(createdAt); // TODO: should parse before?
  return (
    <div className={classNames('flex flex-row items-center gap-2', className)}>
      <div className="text-xs">{formatDate(date)}</div>
      <div className="text-xs">{formatTime(date)}</div>
    </div>
  );
}

function ActivityItemType({
  type,
  className,
}: Pick<ListItemActivity, 'type'> & {
  className?: string;
}) {
  const dictionary = useTranslations();
  const { types, typeAriaLabel } = dictionary['list-activity'];

  return (
    <div className={classNames('flex flex-row items-center gap-1', className)}>
      <div>{type && activityTypesIcons[type]}</div>
      <div className="text-xs" aria-label={typeAriaLabel}>
        {type && types[type]}
      </div>
    </div>
  );
}

function ActivityItemParticipant({
  user,
  userData,
  activityItem,
  participants,
  className,
}: {
  user: AppUser;
  userData: UserData;
  activityItem: ListItemActivity;
  participants: Participant[];
} & {
  className?: string;
}): ReactElement {
  const name = 'ActivityItemParticipant';

  const { id, participantId } = activityItem;

  const {
    participantAriaLabel,
    currentParticipantName,
    noActivityParticipantName,
  } = useTranslations()['list-activity'];

  const userId = user.firebaseUser.uid;

  const participant = participants.find(({ id }) => id === participantId);
  const userContactParticipant = userData.contacts[participantId];

  const onParticipantNameChange = useCallback(
    async ({
      contactId,
      contactName,
    }: {
      contactId: string;
      contactName: string;
    }) => {
      const action = Action.Update;

      const properties = {
        activityItemId: id,
        contactId,
        contactName,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        await updateContactName({
          userId,
          contactId,
          contactName,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
          properties,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
          properties,
        });
      }
    },
    [id, userId],
  );

  return (
    <div className={classNames('flex flex-row items-center', className)}>
      <div className="rounded-full">
        <LiaUserSolid className="text-lg" />
      </div>
      {participantId === userId ? (
        <div className="text-xs p-1">{currentParticipantName}</div>
      ) : participant ? (
        <DebouncedInput
          delay={300}
          className="text-xs p-1 bg-base-100 border-none rounded-md"
          ariaLabel={participantAriaLabel}
          value={userContactParticipant?.name || participant?.name || ''}
          placeholder={`${noActivityParticipantName} #${participant?.index}`}
          onChange={(contactName) =>
            onParticipantNameChange({ contactId: participantId, contactName })
          }
        />
      ) : (
        <div className="text-xs p-1">{participantId.slice(0, 5)}</div>
      )}
    </div>
  );
}

export function ListActivityItem({
  user,
  userData,
  list,
  activityItem,
  participants,
}: {
  user: AppUser;
  userData: UserData;
  list: List;
  activityItem: ListItemActivity;
  participants: Participant[];
}): ReactElement {
  const { id, createdAt, type, itemCategoryId, itemName } = activityItem;

  return (
    <div
      className="px-2 py-2 flex flex-col items-center border border-base-300 shadow-xs rounded-md"
      data-testid={`list-activity-item-${id}`}
    >
      <div className="flex w-full justify-between items-center gap-2">
        <ActivityItemParticipant
          user={user}
          userData={userData}
          activityItem={activityItem}
          participants={participants}
        />
        <ActivityItemDate createdAt={createdAt} />
      </div>
      <div className="w-full flex items-center gap-1 xs:gap-2">
        <ActivityItemType className="w-24" type={type} />
        <ItemName
          className="w-full"
          itemCategoryId={itemCategoryId}
          itemName={itemName}
          customCategories={list.customCategories}
        />
      </div>
    </div>
  );
}
