import {
  CategoryId,
  CustomableCategoryId,
  CustomCategory,
  isCategoryId,
} from '@reshima/category';

export function getCategoryTitle({
  categoryId,
  customCategories,
  categoriesDictionary,
}: {
  categoryId: CustomableCategoryId;
  customCategories: Record<string, CustomCategory>;
  categoriesDictionary: Record<CategoryId, string>;
}) {
  if (categoryId in customCategories) {
    return customCategories[categoryId].name;
  }

  if (isCategoryId(categoryId)) {
    return categoriesDictionary[categoryId];
  }

  return categoriesDictionary.Other;
}
