import { CustomableCategoryId, CustomCategory } from '@reshima/category';
import { Button } from '@reshima/pure-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { useModal } from '@reshima/modals-ui';
import { CategoriesPickerModal } from './categories-picker-modal';
import { CategoryIcon } from './category-icon';

export function CategoriesPickerButton({
  categoryId,
  categoriesOrder,
  customCategories,
  ariaLabel,
  onCategoryIdSelect,
}: {
  categoryId: CustomableCategoryId;
  categoriesOrder?: CustomableCategoryId[];
  customCategories: Record<string, CustomCategory>;
  ariaLabel: string;
  onCategoryIdSelect: (categoryId: CustomableCategoryId) => void;
}) {
  const name = 'CategoriesPickerButton';

  const { heading } = useTranslations()['update-list-item-category'];

  const { showModal } = useModal();

  function onClick() {
    const action = Action.Click;

    const properties = {
      categoryId,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      content: (
        <CategoriesPickerModal
          categoryId={categoryId}
          categoriesOrder={categoriesOrder}
          customCategories={customCategories}
          onCategoryIdSelect={onCategoryIdSelect}
        />
      ),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button onClick={onClick} ghost circle ariaLabel={ariaLabel}>
      <CategoryIcon
        className="p-0.5"
        categoryId={categoryId}
        customCategories={customCategories}
      />
    </Button>
  );
}
