'use client';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  getParticipantsStream,
  Participant,
  List,
  getListStream,
  AppUser,
} from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ReshimaPage } from '@reshima/shared-ui';
import { useNavigate, useSearchParams } from '@reshima/navigation-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import {
  ListItemActivity,
  getListActivityItems,
} from '@reshima/list-activity-shared';
import { ListActivityTable } from '../lib/list-activity-table';
import { Filters, ListActivityFilters } from '../lib/list-activity-filters';
import { ListActivityActionsDropdown } from '../lib/list-activity-actions-dropdown';
import { postTasksActivities } from '../lib/post-list-activities';

export function ListActivityPage() {
  const name = 'ListActivity';

  const {
    'list-activity': {
      error,
      itemsCountAriaLabel,
      itemsCountLabel,
      heading,
      description,
      previousPageAriaLabel,
    },
    'list-text': { defaultListName },
  } = useTranslations();

  const params = useSearchParams();

  const [activityItems, setActivityItems] = useState<ListItemActivity[]>([]);
  const [count, setCount] = useState<number | undefined>();
  const [continuationToken, setContinuationToken] = useState<
    string | undefined
  >();
  const [filters, setFilters] = useState<Filters>({
    endDate: Date.now(),
    startDate: new Date('2024-01-01').getTime(),
  });
  const [isFetchingActivity, setIsFetchingActivity] = useState(true);
  const [isFetchingNextActivity, setIsFetchingNextActivity] = useState(false);
  const [isActivityError, setIsActivityError] = useState(false);

  const [list, setList] = useState<List | undefined>();
  const [isListLoading, setIsListLoading] = useState(true);

  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isParticipantsLoading, setIsParticipantsLoading] = useState(true);
  const [isParticipantsError, setIsParticipantsError] = useState(false);

  const navigate = useNavigate();
  const { user } = useClientAuth();

  const listId = params.get('id');

  const fetchActivity = useCallback(
    async ({
      listId,
      filters,
      user,
    }: {
      filters: Filters;
      listId: string;
      user: AppUser;
    }) => {
      const action = Action.Fetch;

      const properties = {
        listId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      setIsFetchingActivity(true);

      try {
        await postTasksActivities({ user });

        const { items, continuationToken, count } = await getListActivityItems({
          query: { listId, ...filters },
          user,
        });

        setActivityItems(items);
        setContinuationToken(continuationToken);

        if (count !== undefined) {
          setCount(count);
        }

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: {
            ...properties,
            itemsLength: items.length,
            continuationToken: !!continuationToken,
          },
          start,
        });
      } catch (error) {
        setIsActivityError(true);
        trackException({
          name,
          action,
          properties,
          start,
          error,
        });
      }

      setIsFetchingActivity(false);
    },
    [],
  );

  const fetchNextActivity = useCallback(
    async ({
      listId,
      continuationToken,
      filters,
      user,
    }: {
      listId: string;
      continuationToken: string;
      filters: Filters;
      user: AppUser;
    }) => {
      const action = Action.FetchNext;

      const properties = {
        listId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      setIsFetchingNextActivity(true);

      try {
        const response = await getListActivityItems({
          query: { listId, continuationToken, ...filters },
          user,
        });

        setContinuationToken(response.continuationToken);
        setActivityItems((prevItems) => [...prevItems, ...response.items]);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: {
            ...properties,
            nextItemsLength: response.items.length,
            continuationToken: !!response.continuationToken,
          },
          start,
        });
      } catch (error) {
        setIsActivityError(true);
        trackException({
          name,
          action,
          properties,
          start,
          error,
        });
      }

      setIsFetchingNextActivity(false);
    },
    [],
  );

  const listenParticipantsStream = useCallback(
    ({ listId }: { listId: string }) => {
      const action = Action.Fetch;

      const properties = {
        listId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      return getParticipantsStream({
        listId,
        onUpdate: ({ participants }) => {
          setParticipants(participants);
          setIsParticipantsLoading(false);
          setIsParticipantsError(false);
          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties,
            start,
          });
        },
        onError: (error) => {
          setIsParticipantsLoading(false);
          setIsParticipantsError(true);
          trackException({
            name,
            action,
            properties,
            start,
            error,
          });
        },
      });
    },
    [],
  );

  const listenListStream = useCallback(
    ({ listId }: { listId: string }) => {
      const action = Action.Fetch;

      const properties = {
        listId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      return getListStream({
        listId,
        defaultListName,
        onListUpdate: ({ list }) => {
          setList(list);
          setIsListLoading(false);
          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties,
            start,
          });
        },
        onError: (error) => {
          setIsListLoading(false);
          trackException({
            name,
            action,
            properties,
            start,
            error,
          });
        },
      });
    },
    [defaultListName],
  );

  const onScrollLastItem = useCallback(
    ({ listId, filters }: { listId: string; filters: Filters }) => {
      if (
        isFetchingActivity ||
        isFetchingNextActivity ||
        !continuationToken ||
        !user
      ) {
        return;
      }

      fetchNextActivity({ listId, continuationToken, filters, user });
    },
    [
      isFetchingActivity,
      isFetchingNextActivity,
      continuationToken,
      user,
      fetchNextActivity,
    ],
  );

  useEffect(() => {
    if (!listId) {
      trackEvent({
        name,
        action: Action.Load,
        actionModifier: ActionModifier.NotFound,
        properties: { listId },
      });

      navigate('/lists');
      return;
    }
  }, [listId, navigate]);

  useEffect(() => {
    if (!listId) {
      return;
    }

    const unsubscribeListStream = listenListStream({ listId });
    const unsubscribeParticipantsStream = listenParticipantsStream({ listId });

    return () => {
      unsubscribeListStream();
      unsubscribeParticipantsStream();
    };
  }, [listId, navigate, listenListStream, listenParticipantsStream]);

  useEffect(() => {
    if (!listId || !user) {
      return;
    }

    fetchActivity({ listId, filters, user });
  }, [fetchActivity, filters, listId, user]);

  if (!listId || !user || !list) {
    return null; // TODO: loading state
  }

  return (
    <ReshimaPage
      name="ListActivityPage"
      heading={heading}
      description={description}
      previousPage={{
        href: '/list',
        ariaLabel: previousPageAriaLabel,
        keepSearch: true,
      }}
      left={
        <ListActivityActionsDropdown
          disabled={isListLoading}
          list={list}
          onDelete={() => fetchActivity({ listId, filters, user })}
        />
      }
    >
      <div className="max-w-xs w-full mx-auto">
        {isParticipantsError || isActivityError ? (
          <div className="flex flex-col items-center">
            <p>{error}</p>
          </div>
        ) : (
          <div className="flex flex-col gap-5">
            <ListActivityFilters
              participants={participants}
              isParticipantsLoading={isParticipantsLoading}
              filters={filters}
              onFiltersChange={setFilters}
            />
            {isFetchingActivity ? (
              <div className="flex flex-col items-center" data-testid="loading">
                <AiOutlineLoading className="text-3xl animate-spin" />
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <hr className="border-base-300" />
                <div className="flex flex-col gap-0.5">
                  {count ? (
                    <span
                      className="text-sm"
                      aria-label={itemsCountAriaLabel}
                    >{`${count} ${itemsCountLabel}`}</span>
                  ) : null}
                  <ListActivityTable
                    activityItems={activityItems}
                    list={list}
                    participants={participants}
                    onScrollLastItem={() =>
                      onScrollLastItem({ listId, filters })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </ReshimaPage>
  );
}
