import { ReactNode } from 'react';
import { useTranslations } from '@reshima/translations-ui';
import { Placement, Select, SelectOption } from '@reshima/pure-ui';
import {
  CustomableCategoryId,
  CustomCategory,
  getCategoryIcon,
  itemCategories,
  sortCategoriesByOrder,
} from '@reshima/category';
import { Dictionary } from '@reshima/translations';

type EmptyOption = {
  label: string;
  icon: string;
};

function CategoryIcon({ icon }: { icon: ReactNode }) {
  return <div className="text-xl w-6 flex justify-center">{icon}</div>;
}

function LongLabel({ icon, text }: { icon: ReactNode; text: ReactNode }) {
  return (
    <div className="flex flex-1 items-center gap-2 cursor-pointer">
      <CategoryIcon icon={icon} />
      <span>{text}</span>
    </div>
  );
}

function ShortLabel({
  icon,
  text,
  showText,
}: {
  icon: ReactNode;
  text: string;
  showText: boolean;
}) {
  return showText ? (
    <LongLabel icon={icon} text={text} />
  ) : (
    <CategoryIcon icon={icon} />
  );
}

type Props = {
  categoryId?: CustomableCategoryId;
  className?: string;
  placeholder?: ReactNode;
  round?: boolean;
  emptyOption?: EmptyOption;
  placement: Placement;
  showText?: boolean;
  chevron?: boolean;
  categoriesOrder?: CustomableCategoryId[];
  customCategories: Record<string, CustomCategory>;
  onCategoryIdChange: (categoryId: CustomableCategoryId) => void;
};

function getOptions({
  dictionary,
  customCategories,
  categoriesOrder,
  showText,
  emptyOption,
}: {
  dictionary: Dictionary;
  customCategories: Record<string, CustomCategory>;
  categoriesOrder: CustomableCategoryId[];
  showText: boolean;
  emptyOption?: EmptyOption;
}): SelectOption<CustomableCategoryId>[] {
  const fixedCategoriesOptions: SelectOption<CustomableCategoryId>[] =
    itemCategories.map(({ id }) => {
      const icon = getCategoryIcon(id);
      const text = dictionary.categories[id];

      return {
        value: id,
        text,
        shortLabel: <ShortLabel icon={icon} text={text} showText={showText} />,
        longLabel: <LongLabel icon={icon} text={text} />,
      };
    });

  const customCategoriesOptions: SelectOption<CustomableCategoryId>[] =
    Object.values(customCategories).map(({ id, name, icon }) => ({
      value: id,
      text: name,
      shortLabel: <ShortLabel icon={icon} text={name} showText={showText} />,
      longLabel: <LongLabel icon={icon} text={name} />,
    }));

  const options = [...fixedCategoriesOptions, ...customCategoriesOptions];

  const categoriesOrderMap = Object.fromEntries(
    categoriesOrder.map((category, index) => [category, index]),
  ) as Record<CustomableCategoryId, number>;

  options.sort((a, b) =>
    sortCategoriesByOrder({ a: a.value, b: b.value, categoriesOrderMap }),
  );

  if (emptyOption) {
    options.unshift({
      value: undefined as unknown as CustomableCategoryId,
      longLabel: <LongLabel icon={emptyOption.icon} text={emptyOption.label} />,
    });
  }

  return options;
}

export function CategoriesSelect({
  categoryId,
  categoriesOrder = [],
  customCategories,
  className,
  placeholder,
  round = true,
  chevron = true,
  placement,
  showText = false,
  emptyOption,
  onCategoryIdChange,
}: Props) {
  const dictionary = useTranslations();

  const { ariaLabel, searchPlaceholder, noResultsMessage } =
    dictionary['categories-picker'];

  const options = getOptions({
    dictionary,
    customCategories,
    categoriesOrder,
    showText,
    emptyOption,
  });

  return (
    <Select
      ariaLabel={ariaLabel}
      value={categoryId}
      placeholder={placeholder}
      onChange={onCategoryIdChange}
      options={options}
      chevron={chevron}
      round={round}
      placement={placement}
      searchPlaceholder={searchPlaceholder}
      noResultsMessage={noResultsMessage}
      buttonClassName={className}
      contentClassName="max-w-[calc(100vw-1.5rem)]"
      optionsClassName="text-base max-h-56 overflow-y-auto"
      search
      ghost
      tight
    />
  );
}
