import classNames from 'classnames';
import { CustomableCategoryId, CustomCategory } from '@reshima/category';
import { useTranslations } from '@reshima/translations-ui';
import { CategoryIcon } from './category-icon';
import { getCategoryTitle } from './category-title';

export function CategoryLabelIcon({
  categoryId,
  customCategories,
  className,
}: {
  categoryId: CustomableCategoryId;
  customCategories: Record<string, CustomCategory>;
  className?: string;
}) {
  const { categories: categoriesDictionary } = useTranslations();

  return (
    <div className={classNames('flex items-center gap-2', className)}>
      <CategoryIcon
        categoryId={categoryId}
        customCategories={customCategories}
      />
      <div>
        {getCategoryTitle({
          categoryId,
          customCategories,
          categoriesDictionary,
        })}
      </div>
    </div>
  );
}
