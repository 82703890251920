'use client';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useModal } from './context';

export function Modal() {
  const modalRef = useRef<HTMLDialogElement>(null);
  const { modalContent, closeModal } = useModal();
  const { closeButtonAriaLabel } = useTranslations()['modal'];

  const { heading, description, content } = modalContent || {};

  useEffect(() => {
    if (modalContent) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [modalContent]);

  return (
    <dialog
      ref={modalRef}
      className={classNames(
        'group',
        'w-full h-full',
        'max-w-none max-h-none',
        'fixed inset-0',
        'm-0 p-0',
        'flex items-center justify-center',
        'text-base-content',
        'bg-transparent',
        'cursor-auto',
        'pointer-events-none open:pointer-events-auto',
        'opacity-0 open:opacity-100',
        'duration-200',
        'transition-opacity',
      )}
      onClick={(e) => {
        if (e.target === modalRef.current) {
          closeModal();
        }
      }}
      onClose={closeModal}
    >
      <div className="p-2 max-h-screen flex flex-col">
        <div
          className={classNames(
            'relative',
            'min-h-0',
            'flex flex-col gap-2',
            'py-2 px-2 xs:px-4',
            'bg-base-100',
            'rounded-xl shadow-lg',
            'scale-90 group-open:scale-100',
            'transition-transform',
          )}
        >
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-lg">{heading}</h3>
            <Button
              className="size-8"
              ariaLabel={closeButtonAriaLabel}
              ghost
              circle
              onClick={closeModal}
            >
              ✕
            </Button>
          </div>
          {description && <p className="py-2">{description}</p>}
          {content}
        </div>
      </div>
    </dialog>
  );
}
