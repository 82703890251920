import { CustomableCategoryId } from '@reshima/category';
import { Units } from '@reshima/shared';

export enum ItemActivityType {
  added = 'added',
  checked = 'checked',
  unchecked = 'unchecked',
  deleted = 'deleted',
}

export type ListItemActivity = {
  id: string;
  createdAt: number;
  participantId: string;
  type: ItemActivityType;
  listId: string;
  itemId: string;
  itemName: string;
  itemCategoryId: CustomableCategoryId;
  itemUnit: Units;
  itemCount: number;
};

export type NewListItemActivity = Omit<ListItemActivity, 'id'>;

// TODO: Rename
export type NewListItemActivityV2 = Omit<
  ListItemActivity,
  'participantId' | 'listId'
>;

export type ListActivityQueryFilters = {
  listId: string;
  types?: ItemActivityType[];
  participantsIds?: string[];
  startDate: number;
  endDate: number;
  itemId?: string;
  itemCategoryId?: CustomableCategoryId;
  itemName?: string;
};

export type ListActivityQuery = ListActivityQueryFilters & {
  continuationToken?: string;
};

export type ListActivityQueryRequest = Omit<
  ListActivityQuery,
  'startDate' | 'endDate'
> & {
  startDate: string;
  endDate: string;
};

export type QueryListItemActivityResponse = {
  items: ListItemActivity[];
  count?: number;
  continuationToken?: string;
};

export type ListActivityPostRequestPayload = {
  activities: NewListItemActivityV2[];
};
