import { Participant } from '@reshima/firebase';
import { DateInput, DebouncedInput } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { ListActivityQueryFilters } from '@reshima/list-activity-shared';
import { ListActivityTypesMultiSelect } from './list-activity-types-multiselect';
import { ParticipantsMultiSelect } from './participants-multiselect';
import classNames from 'classnames';

export type Filters = Omit<ListActivityQueryFilters, 'listId'>;

type Props = {
  filters: Filters;
  participants: Participant[];
  isParticipantsLoading: boolean;
  onFiltersChange: (filters: Filters) => void;
};

export function ListActivityFilters({
  participants,
  isParticipantsLoading,
  filters,
  onFiltersChange,
}: Props) {
  const {
    filters: {
      searchPlaceholder,
      searchAriaLabel,
      fromDate,
      toDate,
      toDateShort,
    },
  } = useTranslations()['list-activity'];

  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <div className="flex flex-col gap-1">
      <DebouncedInput
        delay={500}
        value={filters.itemName}
        className="text-sm py-1 px-2 border border-base-300 rounded-md bg-base-100"
        onChange={(itemName) => onFiltersChange({ ...filters, itemName })}
        placeholder={searchPlaceholder}
        ariaLabel={searchAriaLabel}
      />
      <div className="flex gap-0.5 xs:gap-1 md:gap-2">
        <ListActivityTypesMultiSelect
          types={filters.types}
          containerClassName="flex-1"
          buttonClassName="text-sm py-1 px-0.5 2xs:px-1"
          onChange={(types) => onFiltersChange({ ...filters, types })}
          secondary
          tight
        />
        <ParticipantsMultiSelect
          secondary
          tight
          participants={participants}
          participantsIds={filters.participantsIds}
          containerClassName="flex-1"
          buttonClassName="text-sm py-1 px-0.5 2xs:px-1"
          disabled={isParticipantsLoading}
          onChange={(participantsIds) =>
            onFiltersChange({
              ...filters,
              participantsIds,
            })
          }
        />
      </div>
      <div
        className={classNames(
          'grid',
          'grid-cols-[auto_1fr] xs:grid-cols-[1fr_auto_1fr]',
          'items-center',
          'gap-y-1 gap-x-1.5 md:gap-x-3',
        )}
      >
        <label className="text-sm xs:hidden">{fromDate}</label>
        <DateInput
          date={filters.startDate}
          className="text-sm w-full"
          max={currentDate}
          onChange={(startDate) => onFiltersChange({ ...filters, startDate })}
        />
        <label className="hidden xs:block text-sm">{toDateShort}</label>
        <label className="text-sm xs:hidden">{toDate}</label>
        <DateInput
          date={filters.endDate}
          className="text-sm w-full"
          max={currentDate}
          onChange={(endDate) => onFiltersChange({ ...filters, endDate })}
        />
      </div>
    </div>
  );
}
