import { useId, useState } from 'react';
import classNames from 'classnames';
import { Input } from '@reshima/pure-ui';
import {
  CustomableCategoryId,
  CustomCategory,
  itemCategories,
  sortCategoriesByOrder,
} from '@reshima/category';
import { useTranslations } from '@reshima/translations-ui';
import { CategoryLabelIcon } from './category-label-icon';

export type CategoriesPickerHandler = {
  focusSearch: () => void;
};

type Props = {
  categoryId?: CustomableCategoryId;
  categoriesOrder?: CustomableCategoryId[];
  customCategories: Record<string, CustomCategory>;
  className?: string;
  onCategoryIdChange: (categoryId: CustomableCategoryId) => void;
};

export function CategoriesPicker({
  categoryId,
  categoriesOrder = [],
  customCategories,
  className,
  onCategoryIdChange,
}: Props) {
  const dictionary = useTranslations();
  const { searchPlaceholder, searchAriaLabel } =
    dictionary['categories-picker'];
  const [search, setSearch] = useState('');
  const radioId = useId();

  const categoriesOrderMap = Object.fromEntries(
    categoriesOrder.map((category, index) => [category, index]),
  ) as Record<CustomableCategoryId, number>;

  const categories = [
    ...Object.entries(customCategories).map(([id, { name }]) => ({
      id,
      label: name,
    })),
    ...itemCategories.map(({ id }) => ({
      id,
      label: dictionary.categories[id],
    })),
  ].filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()));

  categories.sort((a, b) =>
    sortCategoriesByOrder({ a: a.id, b: b.id, categoriesOrderMap }),
  );

  return (
    <div className={classNames('flex-1 flex flex-col gap-2', className)}>
      <div className="w-full p-0.5">
        <Input
          type="text"
          value={search}
          ariaLabel={searchAriaLabel}
          onChange={setSearch}
          placeholder={searchPlaceholder}
          className="w-full"
        />
      </div>
      <div className="flex-1 flex flex-col p-1 overflow-y-scroll">
        {categories.map(({ id }) => {
          const checked = id === categoryId;
          return (
            <div
              key={`${id}-${checked}`}
              className="flex items-center border-b border-base-300 last:border-b-0"
            >
              <input
                type="radio"
                className="radio cursor-pointer"
                name={radioId}
                id={id}
                value={id}
                checked={checked}
                onChange={(e) => onCategoryIdChange(e.target.value)}
              />
              <label htmlFor={id} className="flex-1 py-2 ps-2 cursor-pointer">
                <CategoryLabelIcon
                  categoryId={id}
                  customCategories={customCategories}
                />
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
