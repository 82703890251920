import classNames from 'classnames';
import {
  categoriesMap,
  CustomableCategoryId,
  CustomCategory,
  isCategoryId,
} from '@reshima/category';
import { useTranslations } from '@reshima/translations-ui';
import { getCategoryTitle } from './category-title';

export function getCategoryIcon({
  categoryId,
  customCategories,
}: {
  categoryId: CustomableCategoryId;
  customCategories: Record<string, CustomCategory>;
}) {
  if (isCategoryId(categoryId)) {
    return categoriesMap[categoryId].icon;
  }

  if (categoryId in customCategories) {
    return customCategories[categoryId].icon;
  }

  return categoriesMap.Other.icon;
}

export function CategoryIcon({
  categoryId,
  customCategories,
  className,
}: {
  categoryId: CustomableCategoryId;
  customCategories: Record<string, CustomCategory>;
  className?: string;
}) {
  const {
    categories: categoriesDictionary,
    'category-icon': { categoryAriaLabel },
  } = useTranslations();

  const iconTitle = getCategoryTitle({
    categoryId,
    customCategories,
    categoriesDictionary,
  });

  return (
    <div
      // Specify width because of custom categories
      className={classNames('text-xl w-8 text-center', className)}
      title={iconTitle}
      aria-label={categoryAriaLabel}
    >
      {getCategoryIcon({ categoryId, customCategories })}
    </div>
  );
}
