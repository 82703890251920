import { backendRoutes } from '@reshima/client-server';
import { backendFetch } from '@reshima/backend-fetch';
import {
  ListActivityPostRequestPayload,
  ListActivityQuery,
  NewListItemActivityV2,
  QueryListItemActivityResponse,
} from '@reshima/list-activity-shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { AppUser } from '@reshima/firebase';

const {
  listActivity: { path },
} = backendRoutes;

function getListPath({ listId }: { listId: string }): string {
  return `${path}/${listId}`;
}

export async function postListActivityItems({
  listId,
  activities,
  user,
}: {
  listId: string;
  activities: NewListItemActivityV2[];
  user: AppUser;
}): Promise<void> {
  const name = 'PostListActivityItems';
  const action = Action.Post;

  const properties = {
    listId,
    activitiesLength: activities.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const path = getListPath({ listId });

    const body: ListActivityPostRequestPayload = { activities };

    await backendFetch<void>({
      method: 'POST',
      path,
      body,
      user,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      properties,
      start,
      error,
    });
    throw error;
  }
}

export async function getListActivityItems({
  query,
  user,
}: {
  query: ListActivityQuery;
  user: AppUser;
}): Promise<QueryListItemActivityResponse> {
  const { listId, ...filters } = query;

  const path = getListPath({ listId });

  const params: Record<string, string> = {};

  Object.entries(filters)
    .filter(([, value]) => value)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          params[`${key}[]`] = v;
        });
      } else {
        params[key] = value.toString();
      }
    });

  return backendFetch<QueryListItemActivityResponse>({
    method: 'GET',
    path,
    params,
    user,
  });
}

export async function deleteAllActivityItems({
  listId,
  user,
}: {
  listId: string;
  user: AppUser;
}): Promise<void> {
  const path = getListPath({ listId });

  return backendFetch<void>({
    method: 'DELETE',
    path,
    user,
  });
}
