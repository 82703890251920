import { useEffect, useMemo, useRef } from 'react';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ListItemActivity } from '@reshima/list-activity-shared';
import { List, Participant } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { ListActivityItem } from './list-activity-item';

function getItemHeight() {
  const rootFontSize = +window
    .getComputedStyle(document.body)
    .getPropertyValue('font-size')
    .replace('px', '');

  const wrapperBorders = 1 * 2;
  const wrapperPadding = 0.5 * rootFontSize * 2;
  const wrapperMargin = 0.5 * rootFontSize;
  const firstLine = 0.5 * rootFontSize + rootFontSize;
  const secondLine = 1.75 * rootFontSize;

  return (
    wrapperBorders + wrapperPadding + wrapperMargin + firstLine + secondLine
  );
}

export function ListActivityTable({
  activityItems,
  participants,
  list,
  onScrollLastItem,
}: {
  activityItems: ListItemActivity[];
  list: List;
  participants: Participant[];
  onScrollLastItem: () => void;
}) {
  const listRef = useRef<HTMLDivElement>(null);
  const { noActivity } = useTranslations()['list-activity'];

  const { user, userData } = useClientAuth();

  const estimateSize = useMemo(() => {
    const size = getItemHeight();
    return () => size;
  }, []);

  const virtualizer = useWindowVirtualizer({
    count: activityItems.length,
    estimateSize,
    overscan: 5,
    scrollMargin: listRef.current?.offsetTop || 0,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useEffect(() => {
    const lastVirtualItem = virtualItems[virtualItems.length - 1];

    if (!lastVirtualItem) {
      return;
    }

    if (lastVirtualItem.index >= activityItems.length - 1) {
      onScrollLastItem();
    }
  }, [virtualItems, activityItems, onScrollLastItem]);

  if (!user || !userData) {
    return null;
  }

  return activityItems.length <= 0 ? (
    <p className="text-center">{noActivity}</p>
  ) : (
    <div
      ref={listRef}
      className="relative"
      style={{
        height: `${virtualizer.getTotalSize()}px`,
      }}
    >
      <div
        className="absolute w-full"
        style={{
          transform: `translateY(${virtualItems[0]?.start - virtualizer.options.scrollMargin}px)`,
        }}
      >
        {virtualItems.map(({ index, key }) => {
          const activityItem = activityItems[index];
          return (
            <div
              ref={virtualizer.measureElement}
              key={key}
              data-index={index}
              className="py-1"
            >
              <ListActivityItem
                user={user}
                userData={userData}
                list={list}
                activityItem={activityItem}
                participants={participants}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
